var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container" },
    [
      !_vm.showChangeAddress
        ? _c(
            "div",
            { key: "address" },
            [
              _c("BackNav", {
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              }),
              _c("h1", { staticClass: "text-lg font-bold" }, [
                _vm._v("Delivery information"),
              ]),
              _c("p", { staticClass: "mt-2 text-sm text-grey" }, [
                _vm._v(
                  " Please confirm your delivery address. Ensure you will be available to receive your card during work hours. "
                ),
              ]),
              _vm.addresses.length > 0
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.addresses, function (place) {
                        return _c(
                          "Card",
                          { key: place.id, staticClass: "mt-5" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "cursor-pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectAddress(place)
                                  },
                                },
                              },
                              [
                                _c("p", { staticClass: "text-sm text-dark" }, [
                                  _vm._v(_vm._s(place.line1)),
                                ]),
                                _c("p", { staticClass: "text-sm text-dark" }, [
                                  _vm._v(_vm._s(place.city)),
                                ]),
                                _c("p", { staticClass: "text-sm text-dark" }, [
                                  _vm._v(
                                    _vm._s(place.lga) +
                                      ", " +
                                      _vm._s(place.state)
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "flex justify-end mt-6" },
                                  [
                                    _c("SecondaryButton", {
                                      attrs: { text: "Change Address" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showChangeAddress = true
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _c("Button", {
                        staticClass: "mt-12",
                        attrs: {
                          width: "w-full",
                          text: "Confirm delivery information",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showConfirmAddress = true
                          },
                        },
                      }),
                    ],
                    2
                  )
                : _c(
                    "div",
                    [
                      _c("p", { staticClass: "text-center mt-10" }, [
                        _vm._v("You currently have no address listed"),
                      ]),
                      _c("Button", {
                        staticClass: "mt-12",
                        attrs: {
                          width: "w-full",
                          text: "Add delivery address",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showChangeAddress = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showChangeAddress,
              expression: "showChangeAddress",
            },
          ],
          key: "update",
        },
        [
          _c("BackNav", {
            on: {
              click: function ($event) {
                _vm.showChangeAddress = false
              },
            },
          }),
          _vm.addresses.length > 0
            ? _c("h1", { staticClass: "text-lg font-bold" }, [
                _vm._v(" Change address "),
              ])
            : _c("h1", { staticClass: "text-lg font-bold" }, [
                _vm._v("Add address"),
              ]),
          _c("p", { staticClass: "mt-2 text-sm text-grey" }, [
            _vm._v("Please provide a valid address"),
          ]),
          _c(
            "Card",
            { staticClass: "mt-5" },
            [
              _c("p", { staticClass: "mt-2 text-sm text-grey" }, [
                _vm._v("Fill in the address information"),
              ]),
              _c("Input", {
                staticClass: "mt-4",
                attrs: {
                  id: "address",
                  placeholder: "Give us your address",
                  width: "w-full",
                },
                on: {
                  blur: function ($event) {
                    return _vm.validateAddress()
                  },
                  focus: function ($event) {
                    return _vm.handleError()
                  },
                },
                model: {
                  value: _vm.address.line1,
                  callback: function ($$v) {
                    _vm.$set(_vm.address, "line1", $$v)
                  },
                  expression: "address.line1",
                },
              }),
              _c("transition", { attrs: { name: "fade-out" } }, [
                _vm.errorOnAddress
                  ? _c("p", { staticClass: "text-brandRed text-xs" }, [
                      _vm._v(
                        " Please select a valid address from the dropdown "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("Select", {
                staticClass: "mt-4",
                attrs: {
                  placeholder: "State",
                  label: "name",
                  options: _vm.states,
                  reduce: function (category) {
                    return category.name
                  },
                },
                model: {
                  value: _vm.address.state,
                  callback: function ($$v) {
                    _vm.$set(_vm.address, "state", $$v)
                  },
                  expression: "address.state",
                },
              }),
              _c("Select", {
                staticClass: "mt-4",
                attrs: {
                  placeholder: "LGA",
                  options: _vm.lga,
                  reduce: function (category) {
                    return category.name
                  },
                  label: "name",
                },
                model: {
                  value: _vm.address.lga,
                  callback: function ($$v) {
                    _vm.$set(_vm.address, "lga", $$v)
                  },
                  expression: "address.lga",
                },
              }),
              _c("Button", {
                staticClass: "mt-12",
                attrs: {
                  text: _vm.addresses.length > 0 ? "Update" : "Add",
                  disabled: _vm.disableButton,
                  width: "w-full",
                },
                on: {
                  click: function ($event) {
                    return _vm.confirmAddress()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { display: _vm.showConfirmAddress },
          on: {
            close: function ($event) {
              _vm.showConfirmAddress = false
            },
          },
        },
        [
          _c("h3", { staticClass: "primary-text text-lg font-bold" }, [
            _vm._v("Confirm your address"),
          ]),
          _c("p", { staticClass: "text-grey mt-4" }, [
            _vm._v(
              " Kindly ensure that you have the right address as your delivery address. "
            ),
          ]),
          _c("Button", {
            staticClass: "mt-6",
            attrs: { text: "It's correct", width: "w-full" },
            on: {
              click: function ($event) {
                return _vm.goToPayment()
              },
            },
          }),
          _c(
            "p",
            {
              staticClass:
                "text-sm mt-4 text-center text-grey font-semibold cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.showConfirmAddress = false
                },
              },
            },
            [_vm._v(" Check again ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }