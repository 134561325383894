<template>
  <div class="main-container">
    <!-- <transition mode="out-in" name="slideY"> -->
    <div v-if="!showChangeAddress" key="address">
      <BackNav @click="$router.go(-1)" />
      <h1 class="text-lg font-bold">Delivery information</h1>
      <p class="mt-2 text-sm text-grey">
        Please confirm your delivery address. Ensure you will be available to
        receive your card during work hours.
      </p>
      <div v-if="addresses.length > 0">
        <Card class="mt-5" v-for="place in addresses" :key="place.id">
          <div class="cursor-pointer" @click="selectAddress(place)">
            <p class="text-sm text-dark">{{ place.line1 }}</p>
            <p class="text-sm text-dark">{{ place.city }}</p>
            <p class="text-sm text-dark">{{ place.lga }}, {{ place.state }}</p>
            <div class="flex justify-end mt-6">
              <SecondaryButton
                text="Change Address"
                @click="showChangeAddress = true"
              />
            </div>
          </div>
        </Card>
        <Button
          class="mt-12"
          width="w-full"
          text="Confirm delivery information"
          @click="showConfirmAddress = true"
        />
      </div>
      <div v-else>
        <p class="text-center mt-10">You currently have no address listed</p>
        <Button
          class="mt-12"
          width="w-full"
          text="Add delivery address"
          @click="showChangeAddress = true"
        />
      </div>
    </div>
    <!-- </transition> -->
    <!-- change address -->
    <div v-show="showChangeAddress" key="update">
      <BackNav @click="showChangeAddress = false" />
      <h1 v-if="addresses.length > 0" class="text-lg font-bold">
        Change address
      </h1>
      <h1 v-else class="text-lg font-bold">Add address</h1>
      <p class="mt-2 text-sm text-grey">Please provide a valid address</p>
      <Card class="mt-5">
        <p class="mt-2 text-sm text-grey">Fill in the address information</p>

        <Input
          id="address"
          placeholder="Give us your address"
          class="mt-4"
          v-model="address.line1"
          width="w-full"
          @blur="validateAddress()"
          @focus="handleError()"
        />
        <transition name="fade-out">
          <p v-if="errorOnAddress" class="text-brandRed text-xs">
            Please select a valid address from the dropdown
          </p>
        </transition>
        <Select
          placeholder="State"
          label="name"
          :options="states"
          :reduce="(category) => category.name"
          v-model="address.state"
          class="mt-4"
        />
        <Select
          placeholder="LGA"
          :options="lga"
          v-model="address.lga"
          :reduce="(category) => category.name"
          label="name"
          class="mt-4"
        />
        <Button
          :text="addresses.length > 0 ? 'Update' : 'Add'"
          :disabled="disableButton"
          width="w-full"
          class="mt-12"
          @click="confirmAddress()"
        />
      </Card>
    </div>
    <!-- </transition> -->
    <Modal :display="showConfirmAddress" @close="showConfirmAddress = false">
      <h3 class="primary-text text-lg font-bold">Confirm your address</h3>
      <p class="text-grey mt-4">
        Kindly ensure that you have the right address as your delivery address.
      </p>
      <Button
        text="It's correct"
        width="w-full"
        class="mt-6"
        @click="goToPayment()"
      />
      <p
        class="text-sm mt-4 text-center text-grey font-semibold cursor-pointer"
        @click="showConfirmAddress = false"
      >
        Check again
      </p>
    </Modal>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { SecondaryButton } from "@/UI/Button";
import { BackNav } from "@/UI/Navigation";
import { Input, Select } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { Modal } from "@/UI/Modals";
import Location from "@/database/location.json";
export default {
  components: {
    SecondaryButton,
    BackNav,
    Select,
    Input,
    Button,
    Modal,
  },
  data: () => ({
    showChangeAddress: false,
    showConfirmAddress: false,
    paginateLoading: false,
    loading: false,
    handleMore: false,
    params: {
      pageSize: 20,
      page: 0,
      sortOrder: "ASC",
      from: "",
      to: "",
    },
    address: {
      line1: "",
      line2: "",
      city: "",
      lga: "",
      state: "",
      country: "",
    },
    location: Location,
    lga: [],
    isAddressValid: false,
    errorOnAddress: false,
    check: false,
    newAddress: false,
  }),
  computed: {
    ...mapState({
      addresses: (state) => state?.userInfo?.getAddress,
    }),
    states() {
      return this.location.map((location) => location.state);
    },
    rules() {
      return {
        line1: this.isAddressValid,
        state: this.address.state.length > 0,
        lga: this.address.lga.length > 0,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
  },
  mounted() {
    this.getCurrentAddress();
    this.googleAutocomplete();
  },
  watch: {
    "address.state"(value) {
      this.location.forEach((state) => {
        if (state.state.name === value) {
          this.lga = state.state.locals;
        }
      });
    },
  },
  methods: {
    ...mapActions("card", [
      "getUserAddress",
      "addNewAddress",
      "updateCardDeliveryAddress",
      "saveFullAddress",
    ]),
    ...mapActions("address", [
      "getUserAddress",
      "addNewAddress",
    ]),
    ...mapActions("notification", ["showToast"], "showAlert"),
    confirmAddress() {
      this.showConfirmAddress = true;
      this.newAddress = true;
    },
    selectAddress(address) {
      this.saveFullAddress(address);
      this.updateCardDeliveryAddress(address.id);
      this.showConfirmAddress = true;
    },
    addAddress() {
      this.addNewAddress(this.address)
        .then((res) => {
          this.saveFullAddress(res.data.data);
          this.showConfirmAddress = true;
          // this.getUserWallet();
          this.showToast({
            display: true,
            icon: "success",
            description: "Address added successfully",
          });
          this.updateCardDeliveryAddress(res.data.data.id);
          this.$router.push("/drawer/card/payment");
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    goToPayment() {
      if (this.newAddress) {
        this.addAddress();
      } else {
        this.$router.push("/drawer/card/payment");
      }
    },
    getCurrentAddress(type) {
      // if it is pagination type show loading for pagination else general loading
      type === "paginate"
        ? (this.paginateLoading = true)
        : (this.loading = true);
      this.getUserAddress({
        params: this.params,
        type: type,
      })
        .then((res) => {
          if (res.data.length === this.params.pageSize) {
            this.handleMore = true;
          } else {
            this.handleMore = false;
          }
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        })
        .catch(() => {
          type === "paginate"
            ? (this.paginateLoading = false)
            : (this.loading = false);
        });
    },
    handleLoading(type) {
      type === "paginate"
        ? (this.paginateLoading = false)
        : (this.loading = false);
    },
    handlePagination() {
      this.paginateLoading = true;
      this.params.page++;
      this.getCurrentAddress("paginate");
    },

    // google maps integration starts here
    validateAddress() {
      if (this.isAddressValid) {
        this.errorOnAddress = false;
        document.getElementById("address").style.borderColor = "#000";
      } else {
        this.errorOnAddress = true;
        document.getElementById("address").style.borderColor = "#C70039";
      }
    },
    handleError() {
      if (this.isAddressValid) {
        this.address.line1 = "";
        this.address.state = "";
        this.address.lga = "";
      }
      document.getElementById("address").style.borderColor = "#000";
      this.errorOnAddress = false;
      this.isAddressValid = false;
    },
    googleAutocomplete() {
      // document.getElementById("address").placeholder = "";

      let options = {
        // types: ["geocode"],
        componentRestrictions: { country: "ng" },
      };
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("address"),
        options
      );
      this.autocomplete.addListener("place_changed", () => {
        this.isAddressValid = true;
        this.validateAddress();
        let place = this.autocomplete.getPlace();
        // console.log(place);
        let ac = place.address_components;
        const street = {
          no: "",
          name: "",
          neighborhood: "",
        };

        ac.forEach((address) => {
          if (address.types[0] === "street_number") {
            street.no = address.long_name;
          }
          if (address.types[0] === "route") {
            street.name = address.long_name;
          }
          if (address.types[0] === "neighborhood") {
            this.address.city = address.long_name;
          }
          if (address.types[0] === "administrative_area_level_1") {
            if (address.long_name === "Federal Capital Territory") {
              address.long_name = "FCT";
            }
            this.address.state = address.long_name.split(" State")[0];
          }
          if (address.types[0] === "administrative_area_level_2") {
            if (address.short_name === "AMAC") {
              address.long_name = "Municipal Area Council";
            }
            this.address.lga = address.long_name;
          }
          if (address.types[0] === "country") {
            this.address.country = address.long_name;
          }
        });
        this.address.line1 = place.name;
        if (this.address.state === "" || this.address.lga === "") {
          this.isAddressValid = false;
          this.showAlert({
            display: true,
            description:
              "Please select a valid street address from the dropdown",
            info: "Eg: 19 Babatunde Kuboye Street, Lagos",
            type: "error",
          });
        }
      });
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.main-container {
  max-width: 36.563rem;
  margin: auto;
}
</style>
